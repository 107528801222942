const logger = Logger.getLogger({ scope: 'agnostic', method: 'useStorage' })

// Polyfill problem with disable access to local storage when Cookies are disabled - https://cutt.ly/82TM0sw
if (process.client) {
  let storageSupported = false
  try {
    storageSupported = Boolean(window.localStorage)
  } catch (e) {
    logger.error('Cookies may be disabled as well as local storage producing next error', e)
  }

  if (!storageSupported) {
    Object.defineProperty(window, 'localStorage', {
      value: {
        _data: {},
        setItem(id: string, val: string) {
          this._data[id] = String(val)
          return this._data[id]
        },
        getItem(id: string) {
          return Object.prototype.hasOwnProperty.call(this._data, id) ? this._data[id] : undefined
        }
      }
    })
  }
}

export const getItem = (key: string) => (process.client ? sessionStorage.getItem(key) : null)

export const setItem = (key: string, value: string) => (process.client ? sessionStorage.setItem(key, value) : null)

export const removeItem = (key: string) => (process.client ? sessionStorage.removeItem(key) : null)

export const setLocalStorageItem = (key: string, value: string) =>
  process.client ? localStorage.setItem(key, value) : null

export const getLocalStorageItem = (key: string) => (process.client ? localStorage.getItem(key) : null)
